<template>
  <div class="not-auth">
    <h1 class="flex_center_center w-100 mt-4">
      <b-icon-emoji-expressionless font-scale="2"/></h1>

    <h1>Ой!</h1>
    <p>Пожалуйста авторизуйтесь и разрешите боту отправлять сообщения.</p>
    <p>Это нужно, чтобы мы могли провести оплату и отправить вам контент после покупки.</p>
    
    <div class="mt-4 flex_center_center w-100">
      <vue-telegram-login 
          mode="callback"
          telegram-login="donate2see_bot"
          @callback="telegramAuthCallback" />
    </div>

  </div> 
</template>

<script>

import {vueTelegramLogin} from 'vue-telegram-login'

export default {
  name: "NotAuthUser",
  components: {vueTelegramLogin},  
  computed: {
         
  },  
  methods: {
    telegramAuthCallback(user) {
      // gets user as an input
      // id, first_name, last_name, username,
      // photo_url, auth_date and hash
      console.log(user)
      this.$router.push({name: 'Post',
                      query: user })
    }
  }
};
</script>

<style lang="scss" scoped>
.not-auth{
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 3rem;
}

</style>
