<template>
  <span class="currency"> {{currencyText}} </span> 
</template>

<script>

export default {
  name: "Currency",  
  computed: {
    currencyText(){
      let text = this.value
      if (this.value==='rub') {
        text = '₽'
      } else if (this.value==='usd') {
        text = '$'
      } else if (this.value==='eur') {
        text = 'eu'
      }

      return text
    },        
  },
  props:{
    value: null
  } 
};
</script>

<style lang="scss" scoped>

</style>
