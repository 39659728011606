<template>
  <div class="post">

    <b-row>
      <b-col md="6">
        <div class="post__spoiler shadow " v-show="showPostSpoiler">
          <b-card no-body
            :img-src="postPhotoUrl">
            
            <b-card-body>
              <b-card-text >
                <span v-html="postText"/>
              </b-card-text>
            </b-card-body>

            <b-list-group flush>
              <b-list-group-item class="post__cost flex_start_center w-100">
                <h5 class="mr-auto mb-0">{{ $t("post.description.cost") }}</h5>
                <h2 class="mb-0">{{post.price}} <Currency :value="post.currency"/> </h2>
              </b-list-group-item>
            </b-list-group>

          </b-card>            
        </div>
        <div v-show="!showPostSpoiler" class="flex_center_center">
          <h1 class="flex_center_center w-100 post__emptyspoiler">
            <b-icon-card-image font-scale="3"/></h1>
        </div>
      </b-col>
    
      <b-col md="6">
        <NotAuthUser v-if="notAuthUser"/>

        <div v-if="!notAuthUser">
          <h1>Покупка поста</h1> 
          <p class="text-secondary description">#{{postId}}</p>

          <b-overlay :show="showSpiner" rounded="sm">
            

            <div v-show="!showSpiner">
              <b-list-group class="mb-2">
                <b-list-group-item variant="primary">
                  <h5 class="mb-0">{{ $t("post.description.title") }}</h5>
                </b-list-group-item>
                <b-list-group-item v-if="postContent('text')">
                  {{ $t("post.description.text") }} : {{post.content.textCount}}
                </b-list-group-item>
                <b-list-group-item v-if="postContent('photo')">
                  {{ $t("post.description.photo") }} : {{post.content.photoCount}}
                </b-list-group-item>
                <b-list-group-item v-if="postContent('video')">
                  {{ $t("post.description.video") }} : {{post.content.videoCount}}
                </b-list-group-item>
                <b-list-group-item v-if="postContent('audio')">
                  {{ $t("post.description.audio") }} : {{post.content.audioCount}}
                </b-list-group-item>
                <b-list-group-item v-if="postContent('files')">
                  {{ $t("post.description.files") }} : {{post.content.filesCount}}
                </b-list-group-item>

                <b-list-group-item v-if="postOwner">
                  <span class="text-danger description"> {{ $t("post.description.postOwner") }} </span>       
                </b-list-group-item>

              </b-list-group>

              <div class="flex_center_center mb-4">
                <b-button size="lg" variant="primary" class="buy-button" 
                  @click="buyAction()">
                  
                  Купить</b-button>
              </div>

              <b-alert
                  :show="showUnActiveAlert"
                  dismissible
                  fade
                  variant="danger"
                  @dismiss-count-down="(dismissCountDown)=>{showUnActiveAlert=dismissCountDown}"
                >
                  <span>К сожалению вы не можете произвести покупку, так как автор ограничил продажи.</span>
                </b-alert>
            </div>
          </b-overlay>

        </div>
      </b-col>

    </b-row>
  </div>
</template>

<script>
import Vue from "vue";
import Currency from "./Currency";
import NotAuthUser from "./NotAuthUser";

export default {
  name: "Post",
  components: { 
      Currency, NotAuthUser
   },
  computed: {
    paramsCorrect(){
      return (this.postId && this.$route.query.id && this.$route.query.hash && this.$route.query.auth_date)
    },
    postOwner(){
      return this.post && this.userId ? this.userId === this.post.userId : false
    },
    showPostSpoiler(){
      return true && (this.post.spoilerContent && (this.postText || this.post.spoilerContent.photo))
    },
    postText(){
      let text = this.post.spoilerContent ? this.post.spoilerContent.text : ''      
      return text
    },
    postPhotoUrl(){
      return this.post.spoilerContent && this.post.spoilerContent.photo ? 
          this.post.spoilerContent.photo : 
          'https://ik.imagekit.io/dflk9ts9wd/donate2see/image.png?updatedAt=1683796711950&tr=w-1080%2Ch-1080%2Cfo-auto'
    }

    
  },
  data() {
    return {
      showSpiner: false,
      postId : this.$route.params.post_id ? this.$route.params.post_id : null,
      userId : null,
      post: {},
      notAuthUser: null,
      showUnActiveAlert: 0
    };
  },
  methods: {
    postContent(param){
      return this.post.content && this.post.content[String(param)+'Count']
    },
    async checkLogin(){
     
     if (this.paramsCorrect ) {  
      this.showSpiner = true
      let url = this.$t("brand.apiUrl")+ '/router/post/botlogin/'
       await Vue.axios
          .post(url,
          {headers: {"Content-type": "application/x-www-form-urlencoded",},},
          {params: this.$route.query}
        ).then(res => {
          
          console.log(res.data)
          console.log('auth -> true')
          this.userId = this.$route.query.id
          

          this.getPostInfo()

        })
        .catch(error => {
          this.notAuthUser = true
          this.userId = null
          console.log(error);
        });
        this.showSpiner = false

      } else {       

        this.showSpiner = true
        await this.getPostInfo()
        this.notAuthUser = true
        this.showSpiner = false
      }
    },

    async getPostInfo(){
      let url = this.$t("brand.apiUrl")+ '/router/post/info/?post_id='+this.postId+'&user_id='+this.userId
       await Vue.axios
          .post(url,
          {headers: {"Content-type": "application/x-www-form-urlencoded",},}
        ).then(res => {
                    
          //console.log(res)

          //this.notAuthUser = false
          this.post = res.data
        })
        .catch(error => {
          this.notAuthUser = true
          console.log(error);
        });
    },

    async buyAction(){

      if (this.post.active) {       
      
        this.showSpiner = true
        let url = this.$t("brand.apiUrl")+ '/router/post/makepayment/'
        await Vue.axios
            .post(url,
            {
              postId : this.postId,
              userId: this.userId
            }
          ).then(res => {                      
            //console.log(res)     
            if (res.data.confirmation_url && res.data.orderId) {
              location.href = res.data.confirmation_url
            }       
          })
          .catch(error => {
            console.log(error);
          });

          this.showSpiner = false
      } else {
        this.showUnactiveMsg()
      }
    },
     
    showUnactiveMsg(){

      this.showUnActiveAlert = 5
      
    }
  },
  mounted() {
    this.checkLogin()
  },
  watch: {
    $route(to, from) {
      // обрабатываем изменение параметров маршрута...      
      this.postId = to.params.post_id ? to.params.post_id : null;
      this.checkLogin()
    },
  }, 
  
};
</script>

<style lang="scss" scoped>

.buy-button{
  width: 100%;
}

.post{

  &__spoiler{    

    max-width: 320px;

    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    color: darkgray;
    font-weight: 300;
    font-size: small;
    white-space: pre-line;
  }

  &__emptyspoiler{
    color: lightgray;
  }

  &__cost{
    color: gray;
  }
}

</style> 